// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { useState, useEffect } from "react"
import { PlasmicHomepage } from "./plasmic/blank_project/PlasmicHomepage";
const Airtable = require('airtable');


function Homepage_(props, ref) {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [ipData, setIpData] = useState('')

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch('https://ipapi.co/json/')
    const data = await response.json()
    // Set the IP address to the constant `ip`
    setIpData(data)
  }
  // Run `getIP` function above just once when the page is rendered
  useEffect(() => {
    getIp()
  }, [])


  Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: "key5PXG8hn4y7ZYNR"
  });
  var base = Airtable.base('appZValUWtaYfYVFy');

  const url = 'https://api.airtable.com/v0/appldDJDdY8eUHXyx/tbllxQ7KFFhtwQ29R'

  async function sendDataToAirTable() {
    await base('ContactForm').create([
      {
        "fields": {
          'Message': message,
          'Email': email,
          'Name': name,
          'Time': new Date(Date.now()).toDateString(),
          'ip': JSON.stringify(ipData)
        }
      },

    ], function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      setMessage('')
      setName('')
      setEmail('')
      setSubmitted(true)
      records.forEach(function (record) {
        console.log(record.getId());
      });
    });
  }

  return <PlasmicHomepage parent={{ ref }} {...props}
    message={{
      autoFocus: false,
      value: message,

      onChange: (e) => {
        setMessage(e.target.value);
      },
    }}

    name={{
      autoFocus: false,
      value: name,

      onChange: (e) => {
        setName(e.target.value);
      },
    }}

    email={{
      autoFocus: false,
      value: email,

      onChange: (e) => {
        setEmail(e.target.value);
      },
    }}


    submitButton={{
      onClick: () => {
        sendDataToAirTable()
      },
      submittedStatus: submitted
    }
    }

  />;
}

const Homepage = React.forwardRef(Homepage_);

export default Homepage;
