import React from "react";
import Homepage from "./components/Homepage";



function Index() {
  return (
    <div>
      <Homepage />
    </div>
  );
}

export default Index;